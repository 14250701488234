<template>
  <div class="wrapper referrals-page">
    <main-layout-header :page-title="pageTitle" />
    <div class="page-container referrals-page__content">
      <component :is="referralPageComponent" />
    </div>
  </div>
</template>

<script>
import responsiveMixin from "@/mixins/responsive-mixin";
import ReferralLinkMixin from "@/mixins/referral-link.mixin";
import { mapGetters } from "vuex";
import { loadUserData } from "@/helpers/boot-helpers";
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import store from "@/store";
import { SET_SUPPORT_LINK } from "@/store/const/actions-types";

export default {
  name: "Referrals",
  mixins: [responsiveMixin, ReferralLinkMixin],
  components: {
    MainLayoutHeader,
    ReferralAssistant: () => import("@/components/referral/ReferralAssistant"),
    ReferralPartner: () => import("@/views/Referrals/ReferralPartner"),
    ReferralProgramFinish: () =>
      import("@/components/referral/ReferralProgramFinish"),
    ReferralPartnerAssistant: () =>
      import("@/components/referral/ReferralPartnerAssistant"),
  },
  computed: {
    ...mapGetters(["userData"]),
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
    referralPageComponent() {
      if (this.userData.roles.includes("referral")) {
        return "ReferralAssistant";
      } else if (this.userData.roles.includes("partner")) {
        return "ReferralPartner";
      } else if (this.userData.roles.includes("partner-pending")) {
        return "ReferralProgramFinish";
      } else if (this.userData.roles.includes("common")) {
        return "ReferralPartnerAssistant";
      } else {
        return "";
      }
    },
  },
  async created() {
    await store.dispatch(SET_SUPPORT_LINK, "ref_page");

    await loadUserData();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.wrapper {
  padding: 0 2rem 2rem 1.5rem;
}

.referrals-page {
  &__content {
    margin-top: 3.1875rem;
  }
}

.page {
  &-title {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.375rem;
    color: $mainBlack;
  }
}

@media screen and (max-width: 486px) {
  .wrapper {
    padding: 0 1rem;
  }
  .page {
    &-title {
      font-size: 1.5rem;
    }
  }

  .referrals-page {
    &__content {
      margin-top: 2.25rem;
    }
  }
}
</style>

<style lang="scss">
.referrals-page {
  .main-layout__header-wrapper {
    padding-left: 0;
  }
}
</style>
